var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-cascader',{ref:"cascader",staticStyle:{"display":"block"},attrs:{"popper-class":_vm.multiple ? 'el-cascader__custom-user-multiple' : 'el-cascader__custom-user-single',"options":_vm.deptTree,"value":_vm.value,"props":{
    label: 'name',
    value: 'id',
    multiple: _vm.multiple,
    checkStrictly: true,
    emitPath: false,
    expandTrigger: 'hover',
  },"show-all-levels":false,"clearable":"","filterable":""},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return [_c('span',{staticStyle:{"display":"inline-flex","align-items":"center","width":"100%"}},[(data.itemType === 'action')?_c('span',[_c('span',{staticClass:"btn-confirm",on:{"click":function($event){return _vm.onSelectAll(node, true)}}},[_vm._v("全选")]),_c('span',{staticClass:"btn-cancel",on:{"click":function($event){return _vm.onSelectAll(node, false)}}},[_vm._v("取消")])]):[(data.itemType === 'user')?_c('span',{staticClass:"el-icon-user g-mr-5"}):_vm._e(),_c('span',{staticStyle:{"flex":"1"},on:{"click":function($event){$event.stopPropagation();return _vm.onSelect(data)}}},[_vm._v(_vm._s(data.name))])]],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }